<template>
  <main class="dgrid-body dgrid-extra-gap">
    <h2 class="view-title">{{ $t('enti_titolo_pagina') }}</h2>
    <p class="text--subtitle view-subtitle">{{ $t('enti_descrizione_pagina') }}</p>
    <div class="view-body dgrid-container dgrid-minw-wrap">

      <template v-if="loading">
        <base-cs-institution
          v-for="i in 6"
          :key="i"
          skeleton
        />
      </template>

      <template v-else>
        <template v-for="i in institutionsFiltered">
          <a :href="`institutions/${i.slug}`" :key="i.id" @click.prevent>
            <base-cs-institution
              :item="i"
              @clicked="goToInstitution(i.slug)"
            /> 
          </a>
        </template>      
      </template>

    </div>

    <div class="flow">
      <h3 class="view-title">{{ $t('partners') }}</h3>
      <p class="text--subtitle view-subtitle">{{ $t('enti_descrizione_partner') }}</p>
      <div id="partners" class="stack-horizontal" data-wrap style="--flow-space-horizontal: var(--s3)">
        <a :href="p.url" target="_blank" rel="noopener noreferrer" v-for="p in sortedPartners" class="stack-horizontal" style="--flow-space-horizontal: var(--s0); --flow-space: var(--s0)" data-align="center">
          <div class="partner-logo" v-if="p.logo">
            <img  :src="p.logo">
          </div>
          <p v-else>{{p.name}}</p>
        </a>
      </div>
    </div>
    
    <div class="view-bottom-margin"></div>

  </main>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import utils from '@js/utils.js';
import langMixin from '@mixins/lang.js';

export default {
  name: 'Institutions',
  mixins: [ langMixin ],
  data() {
    return {
      partners: [
        {
          name: "Dotdotdot",
          url: "https://www.dotdotdot.it/",
          order: 5,
          logo: require('@assets/images/partners/dotdotdot.png')
        },
        {
          name: "Fine tuning",
          url: "https://www.finetuning.it/",
          order: 6,
          logo: require('@assets/images/partners/logo_FT.png')
        },
        {
          name: "BIA Srl",
          url: "http://www.bia.cloud/wp/",
          order: 4,
          logo: require('@assets/images/partners/BIA_color_big.png')
        },
        {
          name: "Spoongroup",
          url: "http://spoongroup.it/",
          order: 9,
          logo: require('@assets/images/partners/logo_spoongroup.png')
        },
        {
          name: "Komedia",
          url: "https://www.komedia.it/",
          order: 7,
          logo: require('@assets/images/partners/komedia.png')
        },
        {
          name: "AGA - Associazione giochi Antichi",
          url: "https://www.associazionegiochiantichi.it/home",
          order: 2,
          logo: require('@assets/images/partners/aga.png')
        },
        {
          name: "IANUS",
          url: "http://www.ianusweb.net/",
          order: 8,
          logo: require('@assets/images/partners/ianus_logo.png')
        },
        {
          name: "UNIBAS",
          url: "https://portale.unibas.it/site/home.html",
          order: 1,
          logo: require('@assets/images/partners/unibas.png')
        },
        {
          name: "Musei del Cibo di Parma",
          url: "https://www.museidelcibo.it/",
          order: 3,
          logo: require('@assets/images/partners/musei-del-cibo.png')
        },
      ],
      institutionsSorting: [ 3, 6, 2, 4, 7, 1, 9, 8, 10 ]
    }
  },
  computed: {
    ...mapState('cms', ['institutions', 'loading']),
    sortedPartners() {
      return this.partners.sort((a, b) => a.order - b.order);
    },
    institutionsFiltered() {
      let rtn = this.institutions.filter(el => el.name && el.name.toLowerCase() != 'altri enti');
      return rtn.sort((a, b) => {
        if (!this.institutionsSorting.includes(a.id)) return 1;
        if (!this.institutionsSorting.includes(b.id)) return -1;
        return this.institutionsSorting.indexOf(a.id) - this.institutionsSorting.indexOf(b.id);
      });
    }
  },
  methods: {
    ...mapActions('cms', ['getList']),
    goToInstitution(slug) {
      this.$router.push({name: 'institution', params: { slug }})
    },
    parseMediaUrl(url) {
      return utils.parseMediaUrl(url);
    },
    langCb() {
      this.getList('institutions');
    }
  },
  created() {
    this.getList('institutions');
  }
}
</script>

<style lang="scss">
  #partners {
    // justify-content: center;
  }
  #partners .partner-logo {
    height: var(--s4);
    width: fit-content;
    max-width: var(--s5);

    img {
      height: 100%;
      width: 100%;
      object-fit: contain;
    }
  }
</style>
